*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

:root, [data-theme] {
  background-color: var(--fallback-b1, oklch(var(--b1) / 1));
  color: var(--fallback-bc, oklch(var(--bc) / 1));
}

@supports not (color: oklch(0% 0 0)) {
  :root {
    color-scheme: light;
    --fallback-p: #491eff;
    --fallback-pc: #d4dbff;
    --fallback-s: #ff41c7;
    --fallback-sc: #fff9fc;
    --fallback-a: #00cfbd;
    --fallback-ac: #00100d;
    --fallback-n: #2b3440;
    --fallback-nc: #d7dde4;
    --fallback-b1: #fff;
    --fallback-b2: #e5e6e6;
    --fallback-b3: #e5e6e6;
    --fallback-bc: #1f2937;
    --fallback-in: #00b3f0;
    --fallback-inc: #000;
    --fallback-su: #00ca92;
    --fallback-suc: #000;
    --fallback-wa: #ffc22d;
    --fallback-wac: #000;
    --fallback-er: #ff6f70;
    --fallback-erc: #000;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      color-scheme: dark;
      --fallback-p: #7582ff;
      --fallback-pc: #050617;
      --fallback-s: #ff71cf;
      --fallback-sc: #190211;
      --fallback-a: #00c7b5;
      --fallback-ac: #000e0c;
      --fallback-n: #2a323c;
      --fallback-nc: #a6adbb;
      --fallback-b1: #1d232a;
      --fallback-b2: #191e24;
      --fallback-b3: #15191e;
      --fallback-bc: #a6adbb;
      --fallback-in: #00b3f0;
      --fallback-inc: #000;
      --fallback-su: #00ca92;
      --fallback-suc: #000;
      --fallback-wa: #ffc22d;
      --fallback-wac: #000;
      --fallback-er: #ff6f70;
      --fallback-erc: #000;
    }
  }
}

html {
  -webkit-tap-highlight-color: transparent;
}

* {
  scrollbar-color: color-mix(in oklch, currentColor 35%, transparent) transparent;
}

:hover {
  scrollbar-color: color-mix(in oklch, currentColor 60%, transparent) transparent;
}

:root {
  color-scheme: light;
  --in: 72.06% .191 231.6;
  --su: 64.8% .15 160;
  --wa: 84.71% .199 83.87;
  --er: 71.76% .221 22.18;
  --pc: 89.824% .06192 275.75;
  --ac: 15.352% .0368 183.61;
  --inc: 0% 0 0;
  --suc: 0% 0 0;
  --wac: 0% 0 0;
  --erc: 0% 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 49.12% .3096 275.75;
  --s: 69.71% .329 342.55;
  --sc: 98.71% .0106 342.55;
  --a: 76.76% .184 183.61;
  --n: 32.1785% .02476 255.702;
  --nc: 89.4994% .011585 252.096;
  --b1: 100% 0 0;
  --b2: 96.1151% 0 0;
  --b3: 92.4169% .00108 197.138;
  --bc: 27.8078% .029596 256.848;
}

@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;
    --in: 72.06% .191 231.6;
    --su: 64.8% .15 160;
    --wa: 84.71% .199 83.87;
    --er: 71.76% .221 22.18;
    --pc: 13.138% .0392 275.75;
    --sc: 14.96% .052 342.55;
    --ac: 14.902% .0334 183.61;
    --inc: 0% 0 0;
    --suc: 0% 0 0;
    --wac: 0% 0 0;
    --erc: 0% 0 0;
    --rounded-box: 1rem;
    --rounded-btn: .5rem;
    --rounded-badge: 1.9rem;
    --animation-btn: .25s;
    --animation-input: .2s;
    --btn-focus-scale: .95;
    --border-btn: 1px;
    --tab-border: 1px;
    --tab-radius: .5rem;
    --p: 65.69% .196 275.75;
    --s: 74.8% .26 342.55;
    --a: 74.51% .167 183.61;
    --n: 31.3815% .021108 254.139;
    --nc: 74.6477% .0216 264.436;
    --b1: 25.3267% .015896 252.418;
    --b2: 23.2607% .013807 253.101;
    --b3: 21.1484% .01165 254.088;
    --bc: 74.6477% .0216 264.436;
  }
}

[data-theme="light"] {
  color-scheme: light;
  --in: 72.06% .191 231.6;
  --su: 64.8% .15 160;
  --wa: 84.71% .199 83.87;
  --er: 71.76% .221 22.18;
  --pc: 89.824% .06192 275.75;
  --ac: 15.352% .0368 183.61;
  --inc: 0% 0 0;
  --suc: 0% 0 0;
  --wac: 0% 0 0;
  --erc: 0% 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 49.12% .3096 275.75;
  --s: 69.71% .329 342.55;
  --sc: 98.71% .0106 342.55;
  --a: 76.76% .184 183.61;
  --n: 32.1785% .02476 255.702;
  --nc: 89.4994% .011585 252.096;
  --b1: 100% 0 0;
  --b2: 96.1151% 0 0;
  --b3: 92.4169% .00108 197.138;
  --bc: 27.8078% .029596 256.848;
}

[data-theme="dark"] {
  color-scheme: dark;
  --in: 72.06% .191 231.6;
  --su: 64.8% .15 160;
  --wa: 84.71% .199 83.87;
  --er: 71.76% .221 22.18;
  --pc: 13.138% .0392 275.75;
  --sc: 14.96% .052 342.55;
  --ac: 14.902% .0334 183.61;
  --inc: 0% 0 0;
  --suc: 0% 0 0;
  --wac: 0% 0 0;
  --erc: 0% 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 65.69% .196 275.75;
  --s: 74.8% .26 342.55;
  --a: 74.51% .167 183.61;
  --n: 31.3815% .021108 254.139;
  --nc: 74.6477% .0216 264.436;
  --b1: 25.3267% .015896 252.418;
  --b2: 23.2607% .013807 253.101;
  --b3: 21.1484% .01165 254.088;
  --bc: 74.6477% .0216 264.436;
}

@media (hover: hover) {
  .label a:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  }

  .menu li > :not(ul, .menu-title, details, .btn):active, .menu li > :not(ul, .menu-title, details, .btn).active, .menu li > details > summary:active {
    --tw-bg-opacity: 1;
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    --tw-text-opacity: 1;
    color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
  }
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--rounded-btn, .5rem);
  border-color: #0000;
  border-color: oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity));
  text-align: center;
  border-width: var(--border-btn, 1px);
  --tw-text-opacity: 1;
  height: 3rem;
  min-height: 3rem;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
  background-color: oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
  --tw-border-opacity: 1;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, opacity, box-shadow, transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled], .btn:disabled {
  pointer-events: none;
}

.btn-circle {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

:where(.btn:is(input[type="checkbox"])), :where(.btn:is(input[type="radio"])) {
  appearance: none;
  width: auto;
}

.btn:is(input[type="checkbox"]):after, .btn:is(input[type="radio"]):after {
  --tw-content: attr(aria-label);
  content: var(--tw-content);
}

.checkbox {
  --chkbg: var(--fallback-bc, oklch(var(--bc) / 1));
  --chkfg: var(--fallback-b1, oklch(var(--b1) / 1));
  cursor: pointer;
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  border-width: 1px;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --tw-border-opacity: .2;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.drawer {
  grid-auto-columns: max-content auto;
  width: 100%;
  display: grid;
  position: relative;
}

.drawer-content {
  grid-row-start: 1;
  grid-column-start: 2;
  min-width: 0;
}

.drawer-side {
  pointer-events: none;
  overscroll-behavior: contain;
  inset-inline-start: 0;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-row-start: 1;
  grid-column-start: 1;
  place-items: flex-start start;
  width: 100%;
  height: 100dvh;
  display: grid;
  position: fixed;
  top: 0;
  overflow: hidden;
}

.drawer-side > .drawer-overlay {
  cursor: pointer;
  background-color: #0000;
  place-self: stretch stretch;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  position: sticky;
  top: 0;
}

.drawer-side > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.drawer-side > :not(.drawer-overlay) {
  will-change: transform;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  transform: translateX(-100%);
}

[dir="rtl"] .drawer-side > :not(.drawer-overlay) {
  transform: translateX(100%);
}

.drawer-toggle {
  appearance: none;
  opacity: 0;
  width: 0;
  height: 0;
  position: fixed;
}

.drawer-toggle:checked ~ .drawer-side {
  pointer-events: auto;
  visibility: visible;
  overflow-y: auto;
}

.drawer-toggle:checked ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(0%);
}

.drawer-end > .drawer-toggle ~ .drawer-content {
  grid-column-start: 1;
}

.drawer-end > .drawer-toggle ~ .drawer-side {
  grid-column-start: 2;
  justify-items: end;
}

.drawer-end > .drawer-toggle ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(100%);
}

[dir="rtl"] .drawer-end > .drawer-toggle ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(-100%);
}

.drawer-end > .drawer-toggle:checked ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(0%);
}

@media (hover: hover) {
  .btn:hover {
    --tw-border-opacity: 1;
    border-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-border-opacity)));
    --tw-bg-opacity: 1;
    background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn:hover {
      background-color: color-mix(in oklab, oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity, 1)) 90%, black);
      border-color: color-mix(in oklab, oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity, 1)) 90%, black);
    }
  }

  @supports not (color: oklch(0% 0 0)) {
    .btn:hover {
      background-color: var(--btn-color, var(--fallback-b2));
      border-color: var(--btn-color, var(--fallback-b2));
    }
  }

  .btn.glass:hover {
    --glass-opacity: 25%;
    --glass-border-opacity: 15%;
  }

  .btn-disabled:hover, .btn[disabled]:hover, .btn:disabled:hover {
    --tw-border-opacity: 0;
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    --tw-bg-opacity: .2;
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
    --tw-text-opacity: .2;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn:is(input[type="checkbox"]:checked):hover, .btn:is(input[type="radio"]:checked):hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }
  }

  :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(.active, .btn):hover, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(.active, .btn):hover {
    cursor: pointer;
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  @supports (color: oklch(0% 0 0)) {
    :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(.active, .btn):hover, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(.active, .btn):hover {
      background-color: var(--fallback-bc, oklch(var(--bc) / .1));
    }
  }
}

.label {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.input {
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  border-width: 1px;
  border-color: #0000;
  flex-shrink: 1;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.input[type="number"]::-webkit-inner-spin-button {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-inline-end: -1rem;
}

.input-md[type="number"]::-webkit-inner-spin-button {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-inline-end: -1rem;
}

.menu {
  flex-flow: column wrap;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.menu :where(li ul) {
  white-space: nowrap;
  margin-inline-start: 1rem;
  padding-inline-start: .5rem;
  position: relative;
}

.menu :where(li:not(.menu-title) > :not(ul, details, .menu-title, .btn)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  -webkit-user-select: none;
  user-select: none;
  grid-auto-columns: minmax(auto, max-content) auto max-content;
  grid-auto-flow: column;
  align-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: grid;
}

.menu li.disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  color: var(--fallback-bc, oklch(var(--bc) / .3));
}

.menu :where(li > .menu-dropdown:not(.menu-dropdown-show)) {
  display: none;
}

:where(.menu li) {
  flex-flow: column wrap;
  flex-shrink: 0;
  align-items: stretch;
  display: flex;
  position: relative;
}

:where(.menu li) .badge {
  justify-self: end;
}

.toggle {
  --tglbg: var(--fallback-b1, oklch(var(--b1) / 1));
  --handleoffset: 1.5rem;
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
  --togglehandleborder: 0 0;
  cursor: pointer;
  appearance: none;
  border-radius: var(--rounded-badge, 1.9rem);
  width: 3rem;
  height: 1.5rem;
  color: var(--fallback-bc, oklch(var(--bc) / .5));
  transition: background, box-shadow var(--animation-input, .2s) ease-out;
  box-shadow: var(--handleoffsetcalculator) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset, var(--togglehandleborder);
  background-color: currentColor;
  border-width: 1px;
  border-color: currentColor;
  flex-shrink: 0;
}

.btm-nav > * .label {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .btn {
    animation: button-pop var(--animation-btn, .25s) ease-out;
  }
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .97));
  animation: ease-out button-pop;
}

@supports not (color: oklch(0% 0 0)) {
  .btn {
    background-color: var(--btn-color, var(--fallback-b2));
    border-color: var(--btn-color, var(--fallback-b2));
  }
}

.btn:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

.btn.glass {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: currentColor;
}

.btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn.btn-disabled, .btn[disabled], .btn:disabled {
  --tw-border-opacity: 0;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-bg-opacity: .2;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-text-opacity: .2;
}

.btn:is(input[type="checkbox"]:checked), .btn:is(input[type="radio"]:checked) {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
}

.btn:is(input[type="checkbox"]:checked):focus-visible, .btn:is(input[type="radio"]:checked):focus-visible {
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .98));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.checkbox:focus {
  box-shadow: none;
}

.checkbox:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.checkbox:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .2;
  border-width: 0;
  border-color: #0000;
}

.checkbox:checked, .checkbox[aria-checked="true"] {
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-color: var(--chkbg);
  background-image: linear-gradient(-45deg, transparent 65%, var(--chkbg) 65.99%), linear-gradient(45deg, transparent 75%, var(--chkbg) 75.99%), linear-gradient(-45deg, var(--chkbg) 40%, transparent 40.99%), linear-gradient(45deg, var(--chkbg) 30%, var(--chkfg) 30.99%, var(--chkfg) 40%, transparent 40.99%), linear-gradient(-45deg, var(--chkfg) 50%, var(--chkbg) 50.99%);
}

.checkbox:indeterminate {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, transparent 80%, var(--chkbg) 80%), linear-gradient(-90deg, transparent 80%, var(--chkbg) 80%), linear-gradient(0deg, var(--chkbg) 43%, var(--chkfg) 43%, var(--chkfg) 57%, var(--chkbg) 57%);
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

.drawer-toggle:checked ~ .drawer-side > .drawer-overlay {
  background-color: #0006;
}

.drawer-toggle:focus-visible ~ .drawer-content label.drawer-button {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

.input input {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  background-color: #0000;
}

.input input:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input:focus, .input:focus-within {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.input:has( > input[disabled]), .input-disabled, .input:disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / .4));
}

.input:has( > input[disabled])::placeholder, .input-disabled::placeholder, .input:disabled::placeholder, .input[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.input:has( > input[disabled]) > input[disabled] {
  cursor: not-allowed;
}

.input::-webkit-date-and-time-value {
  text-align: inherit;
}

.join > :where(:not(:first-child)).btn {
  margin-inline-start: calc(var(--border-btn) * -1);
}

:where(.menu li:empty) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .1;
  height: 1px;
  margin: .5rem 1rem;
}

.menu :where(li ul):before {
  bottom: .75rem;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .1;
  content: "";
  inset-inline-start: 0;
  width: 1px;
  position: absolute;
  top: .75rem;
}

.menu :where(li:not(.menu-title) > :not(ul, details, .menu-title, .btn)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  border-radius: var(--rounded-btn, .5rem);
  text-align: start;
  text-wrap: balance;
  padding: .5rem 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

:where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(summary, .active, .btn).focus, :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(summary, .active, .btn):focus, :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):is(summary):not(.active, .btn):focus-visible, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(summary, .active, .btn).focus, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(summary, .active, .btn):focus, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):is(summary):not(.active, .btn):focus-visible {
  cursor: pointer;
  background-color: var(--fallback-bc, oklch(var(--bc) / .1));
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.menu li > :not(ul, .menu-title, details, .btn):active, .menu li > :not(ul, .menu-title, details, .btn).active, .menu li > details > summary:active {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
}

.menu :where(li > details > summary)::-webkit-details-marker {
  display: none;
}

.menu :where(li > details > summary):after, .menu :where(li > .menu-dropdown-toggle):after {
  content: "";
  transform-origin: 75% 75%;
  pointer-events: none;
  justify-self: end;
  width: .5rem;
  height: .5rem;
  margin-top: -.5rem;
  transition-property: transform, margin-top;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  transform: rotate(45deg);
  box-shadow: 2px 2px;
}

.menu :where(li > details[open] > summary):after, .menu :where(li > .menu-dropdown-toggle.menu-dropdown-show):after {
  margin-top: 0;
  transform: rotate(225deg);
}

.mockup-browser .mockup-browser-toolbar .input {
  text-overflow: ellipsis;
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  direction: ltr;
  width: 24rem;
  height: 1.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input:before {
  content: "";
  aspect-ratio: 1;
  --tw-translate-y: -50%;
  height: .75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 2px;
  border-color: currentColor;
  border-radius: 9999px;
  position: absolute;
  top: 50%;
  left: .5rem;
}

.mockup-browser .mockup-browser-toolbar .input:after {
  content: "";
  --tw-translate-y: 25%;
  --tw-rotate: -45deg;
  height: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 1px;
  border-color: currentColor;
  border-radius: 9999px;
  position: absolute;
  top: 50%;
  left: 1.25rem;
}

@keyframes modal-pop {
  0% {
    opacity: 0;
  }
}

@keyframes progress-loading {
  50% {
    background-position-x: -115%;
  }
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes skeleton {
  from {
    background-position: 150%;
  }

  to {
    background-position: -50%;
  }
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

[dir="rtl"] .toggle {
  --handleoffsetcalculator: calc(var(--handleoffset) * 1);
}

.toggle:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.toggle:hover {
  background-color: currentColor;
}

.toggle:checked, .toggle[aria-checked="true"] {
  --handleoffsetcalculator: var(--handleoffset);
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  background-image: none;
}

[dir="rtl"] .toggle:checked, [dir="rtl"] .toggle[aria-checked="true"] {
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
}

.toggle:indeterminate {
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

[dir="rtl"] .toggle:indeterminate {
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

.toggle:disabled {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  opacity: .3;
  --togglehandleborder: 0 0 0 3px var(--fallback-bc, oklch(var(--bc) / 1)) inset, var(--handleoffsetcalculator) 0 0 3px var(--fallback-bc, oklch(var(--bc) / 1)) inset;
  background-color: #0000;
}

.artboard.phone {
  width: 320px;
}

.btn-circle:where(.btn-xs) {
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-circle:where(.btn-sm) {
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-circle:where(.btn-md) {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-circle:where(.btn-lg) {
  border-radius: 9999px;
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.drawer-open > .drawer-toggle {
  display: none;
}

.drawer-open > .drawer-toggle ~ .drawer-side {
  pointer-events: auto;
  visibility: visible;
  overscroll-behavior: auto;
  width: auto;
  display: block;
  position: sticky;
}

.drawer-open > .drawer-toggle ~ .drawer-side > :not(.drawer-overlay), [dir="rtl"] .drawer-open > .drawer-toggle ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(0%);
}

.drawer-open > .drawer-toggle:checked ~ .drawer-side {
  pointer-events: auto;
  visibility: visible;
}

.drawer-open > .drawer-side {
  overflow-y: auto;
}

html:has(.drawer-toggle:checked) {
  scrollbar-gutter: stable;
  overflow-y: hidden;
}

html:has(.drawer-open.drawer-open) {
  scrollbar-gutter: auto;
  overflow-y: auto;
}

.tooltip {
  --tooltip-offset: calc(100% + 1px + var(--tooltip-tail, 0px));
  display: inline-block;
  position: relative;
}

.tooltip:before {
  pointer-events: none;
  z-index: 1;
  content: var(--tw-content);
  --tw-content: attr(data-tip);
  position: absolute;
}

.tooltip:before, .tooltip-top:before {
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-offset);
  transform: translateX(-50%);
}

.drawer-open > .drawer-toggle ~ .drawer-side > .drawer-overlay {
  cursor: default;
  background-color: #0000;
}

.join.join-vertical > :where(:not(:first-child)).btn {
  margin-top: calc(var(--border-btn) * -1);
}

.join.join-horizontal > :where(:not(:first-child)).btn {
  margin-inline-start: calc(var(--border-btn) * -1);
  margin-top: 0;
}

.tooltip {
  text-align: center;
  --tooltip-tail: .1875rem;
  --tooltip-color: var(--fallback-n, oklch(var(--n) / 1));
  --tooltip-text-color: var(--fallback-nc, oklch(var(--nc) / 1));
  --tooltip-tail-offset: calc(100% + .0625rem - var(--tooltip-tail));
  display: inline-block;
  position: relative;
}

.tooltip:before, .tooltip:after {
  opacity: 0;
  transition: color .2s cubic-bezier(.4, 0, .2, 1) .1s, background-color .2s cubic-bezier(.4, 0, .2, 1) .1s, border-color .2s cubic-bezier(.4, 0, .2, 1) .1s, text-decoration-color .2s cubic-bezier(.4, 0, .2, 1) .1s, fill .2s cubic-bezier(.4, 0, .2, 1) .1s, stroke .2s cubic-bezier(.4, 0, .2, 1) .1s, opacity .2s cubic-bezier(.4, 0, .2, 1) .1s, box-shadow .2s cubic-bezier(.4, 0, .2, 1) .1s, transform .2s cubic-bezier(.4, 0, .2, 1) .1s, filter .2s cubic-bezier(.4, 0, .2, 1) .1s, backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s;
}

.tooltip:after {
  content: "";
  border-style: solid;
  border-width: var(--tooltip-tail, 0);
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.tooltip:before {
  white-space: normal;
  background-color: var(--tooltip-color);
  max-width: 20rem;
  color: var(--tooltip-text-color);
  border-radius: .25rem;
  width: max-content;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.tooltip.tooltip-open:before, .tooltip.tooltip-open:after, .tooltip:hover:before, .tooltip:hover:after, .tooltip:has(:focus-visible):after, .tooltip:has(:focus-visible):before {
  opacity: 1;
  transition-delay: 75ms;
}

.tooltip:not([data-tip]):hover:before, .tooltip:not([data-tip]):hover:after {
  visibility: hidden;
  opacity: 0;
}

.tooltip:after, .tooltip-top:after {
  border-color: var(--tooltip-color) transparent transparent transparent;
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-tail-offset);
  transform: translateX(-50%);
}

.m-1 {
  margin: .25rem;
}

.m-2 {
  margin: .5rem;
}

.h-5\/6 {
  height: 83.3333%;
}

.h-96 {
  height: 24rem;
}

.h-full {
  height: 100%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-full {
  width: 100%;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.rounded-box {
  border-radius: var(--rounded-box, 1rem);
}

.border-t-4 {
  border-top-width: 4px;
}

.bg-accent {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-a, oklch(var(--a) / var(--tw-bg-opacity)));
}

.bg-base-200 {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.p-2 {
  padding: .5rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-8 {
  padding-left: 2rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}
/*# sourceMappingURL=index.fca5af61.css.map */
